html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 16px;

  @media screen and (max-width: 1290px) {
    font-size: 90%;
  }
  @media screen and (max-width: 767px) {
    font-size: 80%;
  }
}

html, body, div, span, button, p, button, input {
  font-family: 'IBM Plex Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cm-editor span, .cm-editor div, .cm-editor button {
  font-family: "IBM Plex Mono", monospace !important;
}

* {
  scrollbar-width: thin !important;
}

#root , .app {
  height: 100%;
  width: 100%;
}

.ant-wave, .wave-motion-appear, .wave-motion-appear-active, .wave-motion {
  display: none !important;
}

@keyframes blink{
  0%{opacity: .35;}
  50%{opacity: 1;}
  100%{opacity: .35;}
}
.ant-modal-centered {
  scrollbar-color: var(--fable-scrollbar-color);
}

.ant-modal .ant-modal-content { 
  border-radius: 4px;
}

.ant-modal .ant-modal-content .ant-btn {
  border-radius: 4px;
}


.ant-modal .ant-modal-content .ant-btn:focus-visible {
  outline: none;
}

.ant-modal .ant-modal-content .ant-btn-primary {
  background: #16023e;
}

.ant-modal-confirm-confirm {
  width: 520px !important;
}

.ant-modal-confirm-confirm .ant-modal-content {
  border-radius: 15px !important;
}

.ant-modal-confirm-body-wrapper {
  text-align: center;
}

.ant-modal-confirm-body {
  margin: 1.5rem 1rem 0 1rem;
}

.ant-modal-confirm-body .anticon {
  display: none;
}

.ant-modal-confirm-title {
  flex: auto !important;
}

.ant-modal-confirm-content {
  max-width: 100% !important;
  margin: 1rem 0 !important;
  position: relative;
}

.ant-modal-confirm-confirm .ant-modal-content::before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  height: 40px;
  background: linear-gradient(to right,#FF7450 0%,#FF7450 33.33%,#FEDF64 33.33%,#FEDF64 66.67%,#7567FF 66.67%,#7567FF 100%);
  width: 100%;
  z-index: -1;
  border-radius: inherit;
}

.ant-modal-confirm-btns {
  display: flex;
  gap: 1rem;
  margin: 1.5rem 1rem;
}

.ant-modal-confirm-btns .ant-btn-default {
  border: 1px solid #16023E !important;
  color: #16023E !important;
}

.ant-modal-confirm-btns .ant-btn-default:hover {
  border: 1px solid #16023E !important;
  color: #16023E !important;
}

.ant-modal-confirm-btns .ant-btn-dangerous {
  background: #d64d4d !important;
  color: white !important;
  border: none !important;
}

.ant-modal-confirm-btns .ant-btn-dangerous:hover {
  background: #d64d4d !important;
  color: white !important;
  border: none !important;
}

.ant-modal-confirm-btns .ant-btn-primary {
  background: #7567FF !important;
}

.ant-modal-confirm-btns button {
  flex: 1;
  padding: 0.75rem 2.5rem !important;
  border-radius: 4rem !important;
  height: auto !important;
}


.ant-modal-confirm-btns button:hover {
  transform: translate(2px,-2px) !important;
}

.ant-popover .ant-popover-inner {
  border-radius: 16px !important;
  padding: 16px !important;
}

.ant-tour .ant-tour-inner {
  border-radius: 16px;
  padding: 8px;
}

:root {
  --AnnotationFontSizeMedium: 18px;
  --fable-scrollbar-thumb: rgb(198 201 207);
  --fable-scrollbar-track: rgb(238 238 238);
  --fable-scrollbar-color: rgb(198 201 207) rgb(238 238 238);
}

.ant-tooltip-inner {
  border-radius: 6px !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
}


.typ-h1, .ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title  {
  font-size: 1.65rem;
  font-weight: 400;
  line-height: 1.8rem;
}

.typ-h2 {
  font-size: 1.45rem;
  font-weight: 300;
  line-height: 1.6rem;
}

.typ-reg,.ant-modal-confirm-content {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4rem;
}

.typ-ip, .ant-select-selection-item {
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: "IBM Plex Mono", monospace !important;
}

.typ-btn {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
}

.typ-sm {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;

}

.err-line {
  color: white;
  background: #d64d4d;
  padding: 2px 4px;
  border-radius: 4px;
}

.device-dropdown{
  border-radius: 16px;
  border: 1px solid #EAEAEA;
  background: white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  color: #16023E;
  padding: 0.5rem;
  width: 240px;
}

.device-dropdown .ant-dropdown-menu{
  box-shadow: none;
}

.upgrade-plan{
  position: relative;
  max-height: 100vh;
  max-width: 100vw;
}

.upgrade-plan>*{
  filter: blur(5px);
  pointer-events: none;
}

.upgrade-con {
  position: absolute;
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
  filter: blur(0);
  pointer-events: all;
  z-index: 1000;
}
